
* body{
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
}


.text--center {
    text-align: center;
}
.text--magnified2x {
    font-size: 1.3rem;
}

.business-page-jobs__title {
    font-size: 2rem;
    font-weight: bolder;
    color:#063EF9;
}

.text--center {
    text-align: center;
}


.page-career--container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.page-career--item {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(24, 25, 31, 0.03);
    border-radius: 10px;
    width: 60%;
    margin: 20px 20%;
    background: #EBF0FF;

}

.page-career__position-wanted {
    color: #6F91FC;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin: 0px 0px;
}

.page-career__date {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #18191F;
    margin: 0px 0px;
}

.page-career__description{
    color: #747579;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-bottom: 18px;
}

.center{
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
}
.page-career__title{
    height: 44px;
    border-radius: 40px;
    border: none;
    margin: 0px 16px;
    color: white;
    font-size: 2rem;
    font-weight: bolder;

}

.new-account-start__button-blue {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 22px;
    width: 100%;
    height: 48px;
    background: #063EF9;
    border-radius: 40px;
    border: none;

}


.new-account-start__button-blue-content {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0px 8px;
}

.margin3{
    margin-left: 3px;
}


.navbar {
    position: fixed;
    width: 100vw;
    height: 72px;
    left: 0px;
    top: 0px;
    background-color: #063EF9;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    z-index: 1000000;
}


.navbar_left {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 100px;
    top: 20px;

}

.navbar_right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    position: absolute;
    width: 542px;
    height: 44px;
    right: 100px;
    top: 14px;
}